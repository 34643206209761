import React, { useEffect, useState } from 'react'
import Input from '../../../component/formfields/Input'
import CheckBox from '../../../component/formfields/CheckBox'
import Select from '../../../component/formfields/Select'
import axiosInstance from '../../../utils/axiosInstance';
import { createToast } from '../../../redux/actions/toast';
import { useDispatch } from 'react-redux';
import InfoIcon from '../../../component/common/InfoIcon';

function ProfileEditForm({ data, setData, setEditMode }) {
    const dispatch = useDispatch()

    const [profile, setProfile] = useState({})
    const [carOptions, setCarOptions] = useState([])


    const handleProfileUpdate = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value })
    }


    useEffect(() => {
        axiosInstance.get('/api/cars/')

            .then((res) => {
                const options = res.data.map(item => ({
                    value: item.id,
                    label: item.name
                }))

                //   add empty entry to allow for no default car
                options.unshift({ value: '', label: 'None' })

                setCarOptions(options)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])





    useEffect(() => {
        // copy data to form data so that form is pre-filled with data

        setProfile({
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name, 
            // theme: data.theme, 
            // default_car: data.default_car,
            allow_others_to_view_profile: data.allow_others_to_view_profile,

        })
    }, [data])


    const handleSubmit = (e) => {
        e.preventDefault();

        const postData = {
            first_name: profile.first_name,
            last_name: profile.last_name,
            allow_others_to_view_profile: profile.allow_others_to_view_profile,

            // to be included later
            theme: 'light',
            default_car: null,
            allow_others_to_create_rides: true
            
        }

        axiosInstance.put('/api/v1/profile/me/', postData)
            .then(res => {
                // update data in parent component from api reponse, and set edit mode to false to return to profile view
                setData(res.data)
                setEditMode(false)
                dispatch(createToast('Profile updated successfully', 'success'))
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (

        <div>
            <form onSubmit={e => handleSubmit(e)}>
                <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                        <div className="px-2 py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0 flex items-center">
                            Profile Info

                        </div>
                        <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Email 
                                <InfoIcon text="Email cannot be updated directly, contact your admin for an email change." />
                                </dt>
                            
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {data.email}
                            </dd>
                        </div>

                        <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">First name:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <Input
                                    name="first_name"
                                    labelDisabled={true}
                                    required
                                    onChangeHandler={handleProfileUpdate}
                                    value={profile.first_name} />
                            </dd>
                        </div>

                        <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Last name:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <Input
                                    name="last_name"
                                    labelDisabled={true}
                                    required
                                    onChangeHandler={handleProfileUpdate}
                                    value={profile.last_name}
                                />
                            </dd>
                        </div>


                        {/* <div className="px-2 py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                            Preferences
                        </div>
                        <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Theme:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <Select
                                    name="theme"
                                    labelDisabled={true}
                                    label="Theme"
                                    required
                                    onChangeHandler={handleProfileUpdate}
                                    value={preferences.theme}
                                    options={[
                                        { value: 'light', label: 'Light' },
                                        { value: 'dark', label: 'Dark' }
                                    ]} />
                            </dd>
                        </div> */}


{/* 
                        <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Default car:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <Select
                                    name="default_car"
                                    labelDisabled={true}
                                    onChangeHandler={handlePreferencesUpdate}
                                    value={preferences.default_car}
                                    options={carOptions}
                                />
                            </dd>
                        </div> */}

                        <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Allow other users to view profile:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <CheckBox
                                    name="allow_others_to_view_profile"
                                    labelDisabled={true}
                                    onChangeHandler={e => setProfile({ ...profile, allow_others_to_view_profile: !profile.allow_others_to_view_profile })}
                                    checked={profile.allow_others_to_view_profile}
                                />
                            </dd>
                        </div>
{/* 
                        <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Allow others to create rides:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <CheckBox
                                    name="allow_others_to_create_rides"
                                    labelDisabled={true}
                                    onChangeHandler={e => setPreferences({ ...preferences, allow_others_to_create_rides: !preferences.allow_others_to_create_rides })}
                                    checked={preferences.allow_others_to_create_rides}
                                />

                            </dd>
                        </div> */}


                        <div className="px-2 py-4 ">

                            <div className="flex items-center justify-center mt-2">
                                <button
                                    type="submit"
                                    id="submit"
                                    name="submit"
                                    className="btn bg-orange-500 hover:bg-orange-500 border-orange-600 hover:border-orange-500 text-white rounded-md h-11 justify-center flex items-center"
                                >
                                    Save
                                </button>

                            </div>
                        </div>

                    </dl>
                </div>
            </form >
        </div>
    )
}

export default ProfileEditForm